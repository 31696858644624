import React from 'react'
import showModalDefault from "../../../../../helpers/ui/ModalDefault";
import DialogueImage from "./DialogueImage";
import { URL_PICTURES_FULL } from "../../../../../config";

const renderWrapper = (component, imageData) => {

    let {
        align,
        isLink, attachedLink, nofollow,
        limitHeight = true,
        lightBorder = true,
        heavyBorder = false,
        decoded
    } = imageData;

    let passingData = {
        '--align': align,
        '--max-image-height': limitHeight ? '70rem' : '100%',
        '--image-border': lightBorder ? '0.1rem solid #2a3a45' : 'none',
        '--image-margin': heavyBorder ? '2rem -2rem -2rem 2rem' : '0',
        '--container-margin': heavyBorder ? '0 2rem 2rem 0' : '0'
    }
    let deprecated = !!decoded ? ' image-draft-block--deprecated' : '';

    if (!!isLink) {
        return (
            <a
                href={attachedLink}
                target='_blank'
                className={`image-draft-block${deprecated}`}
                style={passingData}
                rel={!!nofollow ? 'nofollow' : undefined}
            >
                {component}
            </a>
        )
    } else {
        return (
            <div className={`image-draft-block${deprecated}`} style={passingData}>{component}</div>
        )
    }
}

export default function BlockImage({
                                       converted, // when converting to html
                                       imageData, entityKey, context
}) {

    console.log(imageData)

    let _src;
    let {src, image, caption, internal, showCaption} = imageData;

    _src = image ? image : src; // support old data format

    let content = (
        <div className="image-draft-block__container">
            <div className="image-draft-block__container__image-container">
                <img
                    className='image-draft-block__container__image-container__img'
                    src={internal ? URL_PICTURES_FULL + '/' + _src : _src}
                    alt={`image${caption ? ': ' + caption : ''}`}
                />
            </div>
            {caption && showCaption && <p className='image-draft-block__container__caption'>{caption}</p>}
        </div>
    );

    if (!!converted) {
        return renderWrapper(content, imageData)
    } else {
        return renderWrapper(
            <React.Fragment>
                {content}
                {
                    !converted && !context.readOnly &&
                    <div
                        className="image-draft-block__edit-button"
                        onClick={e => {
                            e.preventDefault();
                            showModalDefault({
                                component: (
                                    <DialogueImage
                                        context={context}
                                        imageData={imageData}
                                        entityKey={entityKey}
                                    />
                                ),
                                title: 'Add picture',
                                maxWidth: '1360px',
                            })
                        }}
                    >
                        <p className='image-draft-block__edit-button__title'>Edit <span>(double click)</span></p>
                        <i className="fas fa-edit image-draft-block__edit-button__icon"/>
                    </div>
                }
            </React.Fragment>,
            imageData
        )
    }
}